<template>
  <div>
    <!-- <h2 style="text-align: center; font-size: 28px; font-weight: bold; margin-bottom: 20px;">Jiba</h2> -->
    <!-- <div class="flex justify-center pb-10">
      <img src="../../public/jiba_kenia.png" alt="Jiba" width="100" />
    </div> -->
    <div class="mt-10 sm:mt-0">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h4 class="text-lg font-medium leading-6 text-gray-900">Start, Run & Grow your business online.</h4>
            <p class="mt-1 text-sm text-gray-600 pt-2.5 px-5">Get access to top-class tools for your business, learn how to run a successful e-commerce business on a global scale, expand globaly and sell to hundreds of millions of customers worldwide.</p>
            <!-- <h3 class="text-lg font-medium leading-6 text-gray-900">
              Company Search
            </h3>
            <p class="mt-1 text-sm text-gray-600 pt-2.5 pl-5">
              Start by searching and choosing a name for your company. The name you select
              will be a legal name registered with Companies House, UK
            </p> -->
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2 pr-5 pl-5">
          <form @submit.prevent="sendData" method="POST">
            <div class="shadow overflow-hidden sm:rounded-md">
              <div class="px-4 py-5 bg-white sm:p-6">
                <p class="text-xs text-left">Start by searching and choosing a name for your company. </p>
                <p class="text-xs text-left pb-4">Your company name will be registered with the Companies House, UK. </p>
                <!-- The name you select will be the legal name registered with the Companies House, UK -->
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-3">
                    <input
                      type="text"
                      v-model="compName"
                      @input="nameCheck"
                      name="compName"
                      id="comp-name"
                      required
                      placeholder="Enter your company name"
                      class="focus:ring-blue-800 focus:border-blue-800 block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                      :class="{ unavailable: status }"
                    />
                    <!-- <p style="font-size:12px; text-align: left; padding-left:4px;">Start typing your company name.</p> -->
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <select
                      v-model="companyExtension"
                      class="form-select px-2 py-2 rounded-md w-full shadow-sm sm:text-sm border-gray-300"
                      required
                    >
                      <option value="LTD" selected>Ltd.</option>
                      <option value="LIMITED">Limited</option>
                    </select>
                    <p class="text-xs text-left pl-2">Choose which ending you want for your company.</p>
                  </div>

                  <div class="flex items-center col-span-6 sm:col-span-6">
                    <div :class="{ ldsring: animate }">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>

                    <div>
                      <h6
                        class="text-sm"
                        :class="{
                          availStyle: availColor,
                          availStyle2: availColor2,
                        }"
                      >
                        {{ available }}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="px-4 py-3 bg-white text-right sm:px-6">
                <button
                  class="f600 col-span-3 sm:col-span-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-white mainbgcolor"
                >
                  {{ button }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <dash-footer></dash-footer>
  </div>
</template>

<script>
import axios from "axios";
import DashFooter from '../components/DashFooter.vue'

export default {
  components:{
    DashFooter
  },
  data() {
    return {
      compName: "",
      companyExtension: "",
      button: "Continue",
      avail: "",
      search: "",
      available: "",
      animate: false,
      status: false,
      availColor: false,
      availColor2: false,
    };
  },

  methods: {
    nameCheck() {
      this.animate = true;
      this.status = false;
      if (this.compName.length > 1) {
        const url = "https://app.thriived.com/search";
        axios
          .post(url, {
            compName: this.compName,
          })
          .then((res) => {
            if (res.data > 0) {
              this.avail = "NO";
              this.available =
                "Ooops!, looks like the company name is taken. Try another name";
              this.animate = false;
              this.availColor = false;
              this.availColor2 = true;
            } else {
              this.avail = "YES";
              this.available =
                " Great!, your company name is available for registration. Please continue";
              this.animate = false;
              this.availColor = true;
              this.availColor2 = false;
            }
          });
      } else {
        this.animate = false;
        this.available = "";
      }
    },
    sendData() {
      localStorage.setItem("compName", this.compName + " " +  this.companyExtension);
      if (this.avail == "YES") {
        setTimeout(() => {
          this.$router.push("/company-name");
          this.loading = false;
        }, 2000);
      } else {
        this.status = true;
      }
    },
  },
};
</script>

<style scoped>
button {
  font-weight: 600;
}

.ldsring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.ldsring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  /* margin: 8px; */
  border: 1px solid rgb(36, 76, 168);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(115, 113, 113) transparent transparent transparent;
}
.ldsring div:nth-child(1) {
  animation-delay: -0.45s;
}
.ldsring div:nth-child(2) {
  animation-delay: -0.3s;
}
.ldsring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.unavailable {
  border-color: red;
}
.availStyle {
  color: green;
  font-weight: bold;
  text-align: left;
}
.availStyle2 {
  color: red;
  font-weight: bold;
  text-align: left;
}
</style>
