<template>
  <div>
    <footer>
      <a href="/signin">Sign in</a>
      <a href="/company-search">Sign up</a>
      <!-- <a href="#">Help & Support</a> -->
      <!-- <a href="#">FAQ</a> -->
    </footer>
  </div>
</template>


<script>

export default {
  components: {

  },
}
</script>

<style scoped>
  a{
    font-weight: 600;
    font-size: 12px;
    text-decoration: underline;
    margin-right: 15px;
    color: #2c3e50;
    /* color: rgba(0,0,0,0.7); */
  }
  /* a:hover{
    color: #2c3e50;
  } */

  footer{
    margin-top: 20px;
    margin-bottom: 15px;
    position: initial;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    min-height:50px; 
  }
</style>