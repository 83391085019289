<template>
  <div>
    <dash-header page-title="Dashboard" current=true></dash-header>
    <second-header></second-header>
   
    
    <div class="mt-10">
        <!-- <div class="" style="margin: 30px 0px 0px 30px;">
          <p style="padding:10px 0px 0px 10px; text-align: left; font-weight: 600; color: black; font-size: 20px;">Your company incorporation</p>
        </div> -->
        <div v-if="users.companyInfo.length < 1">
          <a href="/company-search"><button class="f600 mt-5 col-span-3 sm:col-span-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-white mainbgcolor focus:outline-none focus:ring-2 focus:ring-offset-2">Register your company</button></a>
        </div>

        <div v-for="company in users.companyInfo" :key="company.id" class="flex flex-row">
          <div v-if="company.paymentCompleted == false" class="bg-white mx-10 my-10 lg:w-1/2 px-5 py-5 rounded text-left">
            <p style="font-weight: 600; color: black; margin-bottom: 10px;">You have a pending company incorporation awaiting payment.</p>
            <p style="font-size: 16px; margin-bottom: 10px;">Company name: {{ company.companyName }}</p>
            <p style="font-size: 14px; color: rgba(0,0,0,0.7); font-weight: 500; margin-bottom: 10px;">Once payment is complete our team will start the incorporation process.</p>
            <!-- <p>Once payment is complete our team will start the incorporation process.</p> -->
            <hr>
            <button class="f600 mt-5 col-span-3 sm:col-span-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-white mainbgcolor focus:outline-none focus:ring-2 focus:ring-offset-2" @click="payNow(company.id)">Pay now</button>
          </div>
          <!-- <div v-else>
            <p>These companies are not pending payment</p>
          </div> -->
        </div>
      </div>


    <!-- <p>{{ users }}</p>
    <p>{{ payment }}</p> -->
    <dash-footer></dash-footer>
  </div>
</template>

<script>
import DashHeader from '../components/DashHeader.vue'
import DashFooter from '../components/DashFooter.vue'
import SecondHeader from '../components/SecondHeader.vue'
import UsersApi from '../UsersApi'
// import axios from 'axios';
// const url = 'api/users/'  
// const url = '/api/users/'
export default {
  components:{
    DashHeader,
    SecondHeader,
    DashFooter
  },
  data(){
    return{
      users: '',
      payment: '',
      userId: '',
    }
  },

  beforeCreate(){
    UsersApi.getUsers().then(res=>{
      if (res.data.auth != true){
        return this.$router.push('/signin')
      }else{
        this.userId = res.data.user.email
        this.users = res.data.user
        return
      }
    })
  },

  methods:{
    payNow(id){
      return this.$router.push({ path: '/payment', query: { companyId: id, userId: this.userId }})
    }
  }
}
</script>

<style scoped>
.box {
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.004),
  0 6.7px 5.3px rgba(0, 0, 0, 0.008),
  0 12.5px 10px rgba(0, 0, 0, 0.01),
  0 22.3px 17.9px rgba(0, 0, 0, 0.002),
  0 41.8px 33.4px rgba(0, 0, 0, 0.006),
  0 100px 80px rgba(0, 0, 0, 0.02)
;
  height:150px;
  width: 20vw;
  min-height: 120px;
  min-width:300px; 
  /* margin: 100px auto; */
  background: white;
  border-radius: 5px;
}

</style>