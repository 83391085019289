import axios from 'axios'
const url = 'https://app.thriived.com/'
// const localhost = 'http://localhost:3000/'
axios.defaults.withCredentials = true
class UsersApi {
  static getUsers(){
     return axios.get(url)
  }
}

export default UsersApi