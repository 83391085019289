<template>
  <div>
    <dash-header page-title="Dashboard" current=true></dash-header>
    <second-header></second-header>
   
    

    <div class="text-center">
      <div class="" style="margin: 30px 0px 0px 30px;">
        <p style="padding:10px 0px 0px 10px; text-align: left; font-weight: 600; color: black; font-size: 20px;">Your company document</p>
      </div>

      <div class="text-center">
        <div class="bg-white mx-10 my-10 lg:w-1/2 px-5 py-5 rounded text-left">
          <div v-for="company in users.companyInfo" :key="company">
            <div v-if="company.companyRegistered">
              <div class="my-5 mx-5 space-y-4">
                <div class="flex flex-row gap-5">
                  <p class="basis-1/2">Company name: </p>
                  <p class="basis-1/2">{{ company.companyName.toUpperCase() }}</p>
                </div>
                <div class="flex flex-row basis-1/2 gap-5">
                  <p class="basis-1/2">Date of incorporation: </p>
                  <p class="basis-1/2">{{ new Date() }}</p>
                </div>
                <div class="flex flex-row basis-1/2 gap-5">
                  <p class="basis-1/2">Company number: </p>
                  <p class="basis-1/2">{{ company.companyData.companyNumber }}</p>
                </div>
                <div class="flex flex-row basis-1/2 gap-5">
                  <p class="basis-1/2">Document description:</p>
                  <p class="basis-1/2 text-sm">Document include <b>Certificate of Incorporation, Memorandum of association, Person of Significant Control, and Article of association</b></p>
                </div>
              
                <a :href="company.companyData.documentDownloadUrl" target="blank"><button class="f600 mt-5 col-span-3 sm:col-span-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-white mainbgcolor">Download</button></a>
              </div>
            </div>
            <!-- <p v-if="company.companyRegistered">{{ company.companyData }}</p> -->
          </div>
          <div v-if="companyRegistered.length < 1" class="">
            <p>There are no documents yet.</p>
            <a href="/company-search"><button class="f600 mt-5 col-span-3 sm:col-span-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-white mainbgcolor focus:outline-none focus:ring-2 focus:ring-offset-2">Register a new company</button></a> 
          </div>

        </div>
      </div>
     </div> 


  <dash-footer></dash-footer>
  </div>
</template>

<script>
import DashHeader from './DashHeader.vue'
import SecondHeader from './SecondHeader.vue'
import DashFooter from '../components/DashFooter.vue'
import UsersApi from '../UsersApi'
// import axios from 'axios';
// const url = 'api/users/'  
// const url = '/api/users/'
export default {
  components:{
    DashHeader,
    SecondHeader,
    DashFooter
  },
  data(){
    return{
      users: '',
      payment: '',
      companyRegistered: [],
    }
  },

  mounted(){
    UsersApi.getUsers().then(res=>{
      if (res.data.auth != true){
        return this.$router.push('/signin')
      }else{
        res.data.user.companyInfo.map(e=>{
          if(e.companyRegistered == true){
            this.companyRegistered.push(e)
          }
        })
        return this.users = res.data.user
      }
    })
  },

  methods:{
    // payNow(id){
    //   return this.$router.push({ path: '/payment', query: { companyId: id, userId: this.userId }})
    // }
  }
}
</script>

<style scoped>
.box {
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.004),
  0 6.7px 5.3px rgba(0, 0, 0, 0.008),
  0 12.5px 10px rgba(0, 0, 0, 0.01),
  0 22.3px 17.9px rgba(0, 0, 0, 0.002),
  0 41.8px 33.4px rgba(0, 0, 0, 0.006),
  0 100px 80px rgba(0, 0, 0, 0.02)
;
  height:150px;
  width: 20vw;
  min-height: 120px;
  min-width:300px; 
  /* margin: 100px auto; */
  background: white;
  border-radius: 5px;
}

</style>