<template>
  <div>
    <!-- <h2 style="text-align: center; font-size: 28px; font-weight: bold; margin-bottom: 20px;">Jiba</h2> -->
    <!-- <div class="flex justify-center pb-10">
      <img src="../../public/jiba_kenia.png" alt="Jiba" width="100" />
    </div> -->
    <div class="mt-10 sm:mt-0">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="pl-5 md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Standard Industrial Classification (SIC)
            </h3>
            <p class="mt-1 text-sm text-gray-600">
              SIC codes are used by the government to track trends in the UK economy. <br>It can be hard to find an exact match but please choose as best you can
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2 pr-5 pl-5">
          <form @submit.prevent="sendData">
            <div class="shadow overflow-hidden sm:rounded-md">
              <div class="px-4 py-5 bg-white sm:p-6">
                <div class="flex pb-5 justify-between">
                  <div class="col-span-6 sm:col-span-3 items-start text-sm text-left">Company registration for:
                    <b>{{ searchComp }}</b>
                  </div>
                  <div class="col-span-6 sm:col-span-3 flex gap-1 content-between items-center justify-end pb-5">
                    <h5 class="text-sm">Progress:</h5>
                    <div class="progress-bar"></div>
                    <div class="progress-bar progress orange"></div>
                    <div class="progress-bar progress orange"></div>
                    <div class="progress-bar progress orange"></div>
                    <div class="progress-bar progress"></div>
                  </div>
                </div>
                <div class="grid grid-cols-6 gap-6">
                  <div class="pb-5 col-span-6 sm:col-span-3" style="text-align: left;">
                    <label class="f600" for="industry">Enter your business activity</label>
                    <input type="text" id="industry" v-model="industry" class="mt-1 block w-full shadow-sm sm:text-sm rounded-md" required>

                    <h6 class="pt-2 text-sm">Example: Software development, School, Graphic designer, Freelancer {{ borderColor }}</h6>
                  </div>
                  <div class="col-span-6 sm:col-span-3"></div>

                  <button
                    class="f600 mainbgcolor col-span-3 sm:col-span-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white"
                    @click="previous"
                  >
                    Back
                  </button>
                  <button
                    class="f600 col-span-3 sm:col-span-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white" style="background-color: #244ca8;" type="submit" 
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <dash-footer></dash-footer>
  </div>
</template>

<script>
import DashFooter from '../components/DashFooter.vue'

export default {
  components:{
    DashFooter
  },
  data() {
    return {
      searchComp: localStorage.getItem("compName"),
      industry: "",
      payment: "pending",
      isActive: false,
    };
  },

  methods: {
    sendData() {
      const data = {
        industry: this.industry,
        payment: this.payment
      }
      localStorage.setItem("companyInfo", JSON.stringify(Object.assign(JSON.parse(localStorage.getItem('companyInfo')), data)));
      console.log(localStorage.getItem("companyInfo"));
      this.$router.push("/agreement");
    },
    previous() {
      this.$router.push("/company-address");
    },
  },
};
</script>

<style scoped>
.orange {
  background-color: #244ca8
}
/* input{
  -webkit-appearance: none;
} */

</style>