<template>
  <div>
    <div class="mt-10 sm:mt-0">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900 mb-4">Review and pay</h3>

              <p class="mt-1 text-sm text-gray-600">You are incorporating: <b>{{ searchComp }}</b></p>
              <p class="mt-1 text-sm text-gray-600">One-time cost of incorporation: <b>₦35,000</b> </p>
              <p class="mt-1 text-sm text-gray-600">Processing time: <b>24 hours</b> </p>
              <!-- <b>Once payment is complete our team will start the incorporation process.</b> -->

          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">

            <div class="shadow overflow-hidden sm:rounded-md mr-5 ml-5">
              <div class="px-4 py-5 bg-white sm:p-6">
                <!-- <div class="grid grid-cols-6 gap-6"> -->

                  <!-- loader animation -->
                  <div v-if="loader" class="container pt-4 pb-4">
                    <div class="row">
                      <div class="col-sm-6 text-center">
                        <p class="text-xs mb-2">You are being redirected to payment page</p>        
                        <div class="loader1">
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div> 

                  <div v-if="show"> 
                    <p class="font-medium text-grey text-left">Choose payment method</p>
                    <p class="text-red-600 text-xs">{{ error }}</p>

                    <div class="lg:mt-3 lg:ml-5 md:mt-3 md:ml-5 text-left">
                      <label
                      class="bgcolor border pl-4 mb-5 pt-5 pb-5 block text-sm font-medium text-gray-700 rounded"
                      >
                        <div class="flex">
                          <input
                            name="payment_method"
                            type="radio"
                            id="outline"
                            v-model="payType"
                            value="card"
                            class="mr-2 focus:outline-none focus:ring-transparent focus:border-transparent"
                            @change="updateCardFee"
                          />
                          <b>Pay with card</b>
                        </div>
                        <div class="mt-2 ml-6">
                          <!-- <p class="mb-2">Delivery time: <b>30min - 10hours</b></p> -->
                          <p class="mb-2">Payment method fee: <b class="text-green-800">FREE</b></p>
                        </div>
                        <div class="ml-6">
                          <!-- <p>You will be ask to log into your bank account to complete your transaction</p> -->
                          <p class="text-xs">You will be redirected to Paystack webite where you can securely complete your payment.</p>
                          <div class="flex gap-2">
                            <p class="text-xs">Transaction is secured by </p>
                            <img src="../../public/paystack-2.svg" alt="paystack" width="80">
                          </div>
                        </div>
                      </label>
                    </div>

                    <div class="lg:ml-5 md:ml-5 text-left">
                      <label
                      class="bgcolor border pl-4 mb-5 pt-5 pb-5 block text-sm font-medium text-gray-700 rounded"
                      >
                        <div class="flex">
                          <input
                            name="payment_method"
                            type="radio"
                            id="outline"
                            v-model="payType"
                            value="bank"
                            class="mr-2 focus:outline-none focus:ring-transparent focus:border-transparent"
                            @change="updateBankFee"
                          />
                          <b>Pay with bank</b>
                        </div>
                        <div class="mt-2 ml-6">
                          <p class="mb-2">Payment method fee: <b class="text-green-800">FREE</b></p>
                        </div>
                        <div class="ml-6">
                          <!-- <p>You will be ask to log into your bank account to complete your transaction</p> -->
                          <p class="text-xs">You will be redirected to Mono webite where you can securely complete your payment.</p>
                          <div class="flex gap-2">
                            <p class="text-xs">Transaction is secured by </p>
                            <img src="../../public/mono-logo.svg" alt="mono" width="60">
                          </div>
                        </div>
                      </label>
                    </div>

                    
      
                    <div class="flex justify-between">
                      <button
                          class="f600 mainbgcolor col-span-6 sm:col-span-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-white "
                          onclick="history.back()"
                        >
                          Back
                        </button>
                        <button
                          type="submit"
                          class="f600 col-span-6 sm:col-span-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-white "
                          style="background-color: #244ca8"
                          @click="goToPayment"
                        >
                          Go to Payment
                        </button>


                    </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <dash-footer></dash-footer>
  </div>
</template>

<script>
import DashFooter from '../components/DashFooter.vue'

import UsersApi from '../UsersApi'
import axios from 'axios'
const prodUrl = 'https://app.thriived.com/'
// const localhost = 'http://localhost:3000/'
export default {
  components:{
    DashFooter
  },
  
  data() {
    return{
      searchComp: localStorage.getItem('compName'),
      loader: false,
      show: true,
      payType: '',
      error: '',
      userId: '',
      companyId: '',
      amount: 35000 * 100
    }
  },

  mounted(){
      UsersApi.getUsers().then(res=>{
        if(res.data.auth != true){
          return this.$router.push('/signin')
        }
        this.userId = this.$route.query.userId
        this.companyId = this.$route.query.companyId
        return
    })
  },
  methods:{
    goToPayment(){
      this.error = ''
      const data = {
        email: this.userId,
        amount: this.amount,
        companyName: this.searchComp,
        payType: this.payType,
        companyId: this.companyId
      }

      let url;
      if(this.payType == 'card'){
        url = `${prodUrl}paystack-payment`
      }else{
        url = `${prodUrl}bank-payment-mono`
      }


      if(this.payType == ''){  
        return this.error = 'Please select a payment method below'
      }else{
       axios.post(url, data)
       .then(res=>{
        //  console.log(res.data); 
        this.loader = true
        this.show = false
        setTimeout(()=>{
          window.location.href = res.data
        }, 2000)
       })
      }

    },
     previous() {
      this.$router.push("/agreement");
    },
  }
}
</script>

<style scoped>
.bgcolor{
  background-color: rgba(142, 231, 232, 0.1);
}


.loader1 {
   display:inline-block;
   font-size:0px;
   padding:0px;
}
.loader1 span {
   vertical-align:middle;
   border-radius:100%;
   
   display:inline-block;
   width:10px;
   height:10px;
   margin:3px 2px;
   -webkit-animation:loader1 0.8s linear infinite alternate;
   animation:loader1 0.8s linear infinite alternate;
}
.loader1 span:nth-child(1) {
   -webkit-animation-delay:-1s;
   animation-delay:-1s;
  background:rgba(36, 76, 168, 0.6)
}
.loader1 span:nth-child(2) {
   -webkit-animation-delay:-0.8s;
   animation-delay:-0.8s;
  background:rgba(36, 76, 168, 0.8)
}
.loader1 span:nth-child(3) {
   -webkit-animation-delay:-0.26666s;
   animation-delay:-0.26666s;
  background:rgba(36, 76, 168, 1)
}
.loader1 span:nth-child(4) {
   -webkit-animation-delay:-0.8s;
   animation-delay:-0.8s;
  background:rgba(36, 76, 168, 0.8)
  
}
.loader1 span:nth-child(5) {
   -webkit-animation-delay:-1s;
   animation-delay:-1s;
  background:rgba(36, 76, 168, 0.4)
}

@keyframes loader1 {
   from {transform: scale(0, 0);}
   to {transform: scale(1, 1);}
}
@-webkit-keyframes loader1 {
   from {-webkit-transform: scale(0, 0);}
   to {-webkit-transform: scale(1, 1);}
}


</style>