<template>
  <div class="overflow-x-auto flex space-x-5" style="margin:0px 30px 0px 30px; text-align: left; ">
      <a href="/company-search">
        <div class="box justify-center" style="padding: 25px 20px 15px 20px;">
          <!-- <p>STEP 1</p> -->
          <p style="font-size: 16px; font-weight: 500; padding-top:3px;">Register Your Company</p>
          <p style="font-size: 12px; color: rgba(0,0,0,0.8); font-weight: 500; padding-top: 5px;">It only takes 24 hours to complete.</p>
        </div>
      </a>   
      
      <a href="/company-search">
        <div class="box justify-center" style="padding: 25px 20px 15px 20px;">
          <!-- <p>STEP 2</p> -->
          <p style="font-size: 16px; font-weight: 500; padding-top:3px;">Open a Business Bank Account</p>
          <p style="font-size: 12px; color: rgba(0,0,0,0.8); font-weight: 500; padding-top: 5px;">We partner with top banks to help you set up a bank account for your business .</p>
        </div>
      </a>

      <a href="/company-search">
        <div class="box justify-center" style="padding: 25px 20px 15px 20px;">
          <!-- <p>STEP 3</p> -->
          <p style="font-size: 16px; font-weight: 500; padding-top:3px;">Set up Stripe, Paypal and more. Collect payment anywhere.</p>
          <p style="font-size: 12px; color: rgba(0,0,0,0.8); font-weight: 500; padding-top: 5px;">You're a step away from global payment infrastructure. </p>
        </div>
      </a>

      <a href="/company-search">
        <div class="box justify-center" style="padding: 25px 20px 15px 20px;">
          <!-- <p>STEP 4</p> -->
          <p style="font-size: 16px; font-weight: 500; padding-top:3px;">Sell globally on Amazon, eBay, Walmart</p>
          <p style="font-size: 12px; color: rgba(0,0,0,0.8); font-weight: 500; padding-top: 5px;">Put your product in front of hundreds of millions of buyers. We provide guides and tools to help you sell globally.</p>

        </div>
      </a>
    </div>
</template>

<style scoped>
.box {
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.004),
  0 6.7px 5.3px rgba(0, 0, 0, 0.008),
  0 12.5px 10px rgba(0, 0, 0, 0.01),
  0 22.3px 17.9px rgba(0, 0, 0, 0.002),
  0 41.8px 33.4px rgba(0, 0, 0, 0.006),
  0 100px 80px rgba(0, 0, 0, 0.02)
;
  height:150px;
  width: 20vw;
  min-height: 120px;
  min-width:300px; 
  /* margin: 100px auto; */
  background: white;
  border-radius: 5px;
}
</style>