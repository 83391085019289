<template>
  <div>
    <keep-alive>
      <component :is="currentPage"></component>
    </keep-alive>
  
    <button class="btn prev" @click="previous">Prev</button>
    <button class="btn" v-if="this.currentPage != 'PreAgreement'" @click="next">Next</button>
    <button class="btn" v-if="this.currentPage == 'PreAgreement'" @click="complete">Complete</button>
    <p>{{ msg }}</p>
  <dash-footer></dash-footer>
  </div>
</template>

<script>
import DashFooter from '../components/DashFooter.vue'
// import CompName from './components/CompName.vue'
// import CompLocation from './components/CompLocation.vue'
// import PreAgreement from './components/PreAgreement.vue'
// import CompType from './components/CompType.vue'
// import CompIndustry from './components/CompIndustry.vue'
// import CompSearch from './regComp/CompSearch.vue'
// import GettingStarted from './regComp/gettingStarted/GettingStarted.vue'

export default {
  components: {
    DashFooter
    // CompName,
    // CompLocation,
    // PreAgreement,
    // CompType,
    // CompIndustry,
  },
  data() {
    return{
      page: "Next",
      idx: 0,
      currentIdx: 0,
      tabs: ['CompName','CompType', 'CompLocation', 'CompIndustry', 'PreAgreement'],
      msg: '',
      currentPage: 'CompName',
      compInfo:{
        fullName: '',
        companyName: '',
      }
    }
  },

  methods:{
    switchComp(value){
      console.log(value);
      this.msg = value;
    },
    previous(){ 
      if(this.currentIdx <= 0){
        this.currentPage = 'CompName'
      }else{
        this.currentIdx -= 1
        this.currentPage = this.tabs[this.currentIdx]
      }
    },

    next(){
      if(this.currentIdx >= this.tabs.length - 1){
        this.currentPage = this.tabs[this.tabs.length - 1]
      }else{
        this.currentIdx +=1 
        this.currentPage = this.tabs[this.currentIdx]
      }
      // if(this.currentPage == 'PreAgreement'){
      //   this.page = "Complete"
      // }
      // if(this.page == 'Complete'){
      //   setTimeout(()=>{
      //       this.$router.push('/payment')
      //     }, 2000)
      // }
    },
    complete(){
      setTimeout(()=>{
            this.$router.push('/payment')
          }, 2000)
    }
    }
}
</script>

<style scoped>
  .btn{
    padding: 10px;
    color: white;
    border-radius: 5px;
    background-color: purple;
  }
  .prev{
    margin-right: 40%;
  }
</style>