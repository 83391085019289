import { createApp } from 'vue'
import { createRouter, createWebHistory} from 'vue-router'

import App from './App.vue'
import UsersReg from './components/UsersReg.vue'
// import HomePage from './components/HomePage.vue'
import DashBoard from './components/DashBoard.vue'
import UsersAuth from './components/UsersAuth.vue'
import UsersAuth2 from './components/UsersAuth2.vue'
import StackedDash from './components/StackedDash.vue'
import OverView from './components/OverView.vue'
import DashHeader from './components/DashHeader.vue'
import CompReg from './components/CompReg.vue'
import CompSearch from './components/CompLookup.vue'
import PaymentPage from './components/PaymentPage.vue'
import CompLocation from './components/CompLocation.vue'
import CompIndustry from './components/CompIndustry.vue'
import CompName from './components/CompName.vue'
import CompType from './components/CompType.vue'
import CompAgreement from './components/CompAgreement.vue'
import PaymentSuccess from './components/PaymentSuccess.vue'
import MailRoom from './components/MailRoom.vue'
import CompanyDocument from './components/CompanyDocument.vue'
import './index.css'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {path: '/', component: DashBoard}, //domain.com/
    {path: '/register', component: UsersReg}, //domain.com/register
    {path: '/dashboard', component: DashBoard}, //domain.com/dashboard
    {path: '/signin', component: UsersAuth}, //domain.com/login
    {path: '/auth', component: UsersAuth2}, //domain.com/login
    {path: '/stackeddash', component: StackedDash}, 
    {path: '/overview', component: OverView}, 
    {path: '/dashheader', component: DashHeader}, 
    {path: '/company-registration', component: CompReg},
    {path: '/company-search', component: CompSearch}, 
    {path: '/payment', component: PaymentPage},
    {path: '/company-address', component: CompLocation}, 
    {path: '/company-industry', component: CompIndustry}, 
    {path: '/company-name', component: CompName}, 
    {path: '/company-type', component: CompType}, 
    {path: '/agreement', component: CompAgreement}, 
    {path: '/payment-status', component: PaymentSuccess}, 
    {path: '/mail', component: MailRoom}, 
    {path: '/document', component: CompanyDocument}, 
    {path: '/.*', component: UsersReg} //handles 404 routes

  ]
})
const app = createApp(App)

app.use(router)

app.mount('#app')
