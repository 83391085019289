<template>
  <div>
    <div v-if="loader" class="container pt-4 pb-4">
      <div class="row">
        <div class="col-sm-6 text-center">
          <p class="text-xs mb-2">Waiting for response</p>        
          <div class="loader1">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div> 

    <div v-if="show === true" class="center">
      <div class="circle-loader " :class="{ loadcomplete: isLoadComplete }">
        <div class="draw" :class="{ checkmark: isCheckmark }"></div>
      </div>
      <p>Your payment is successful</p>
      <div class="mt-2">
        <a class="font-medium underline" href="/">Go to your dashboard</a>
      </div>
    </div>
    
    <div v-if="feedback === true" class="center">
      <div id="cent">
        <img src="https://cdn-icons-png.flaticon.com/512/1828/1828843.png" width="100" alt="payment_fail">
      </div>
      <p>We couldn't confirm your payment</p>
      <p>{{ reference ? `Please contact us and provide this reference number: ${ reference }` : "Please contact us." }}</p>
      <div class="mt-4 flex gap-5 justify-center">
        <a class="font-medium underline" href="/">Go to your dashboard</a>
        <a class="font-medium underline" href="/">Contact us</a>
      </div>
    </div>

    <div v-if="error === true" class="center">
      <div id="cent">
        <img src="https://cdn-icons-png.flaticon.com/512/1828/1828843.png" width="100" alt="payment_fail">
      </div>
      <p>Something went wrong. Please contact us.</p>
      <div class="mt-4 flex gap-5 justify-center">
        <a class="font-medium underline" href="/">Go to your dashboard</a>
        <a class="font-medium underline" href="/">Contact us</a>
      </div>
    </div>


  <dash-footer></dash-footer>
  </div>
</template>

<script>
/* eslint-disable */
import DashFooter from '../components/DashFooter.vue'
import axios from 'axios'
axios.defaults.withCredentials = true

import UsersApi from '../UsersApi'

const prodUrl = "https://app.thriived.com/"
// const localhost = "http://localhost:3000/"

export default {
  components:{
    DashFooter
  },
  
  data(){
    return{
      reference: '',
      users: '',
      paymentStatus: '',
      show: false,
      feedback: false,
      error: false,
      initial: '',
      isLoadComplete: false,
      isCheckmark: false,
      payType: '',
      loader: true,
    }
  },

  mounted(){
    setTimeout(()=>{
      this.isLoadComplete = true
      this.isCheckmark = true
    }, 1000)

    UsersApi.getUsers().then(res=>{
      if (res.data.auth != true){
        this.payType = res.data.payType
        return this.$router.push('/signin')
      }
      return this.users = res.data.user

    })
    let url = '';

    if(this.payType == 'bank'){
      url = `${prodUrl}verify-mono-payment`
      this.reference = this.$route.query.reference
    }else{
      url = `${prodUrl}verify-paystack-payment`
      this.reference = this.$route.query.trxref
    }

      const params = {
        reference: this.reference,
      }

      axios.post(url, params) 
      .then(res=>{
        switch(res.data.status) {
          case "verified":
            this.show = true
            this.loader = false
            break;
          case "unverified":
            this.feedback = true
            this.loader = false
            break;
          default:
            this.error = true
            this.loader = false
        }
      })
  }
}
</script>

<style scoped>
  #cent{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* border: 1px solid; */
    padding: 30px;
    /* border-radius: 5px; */
    
    
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.004),
    0 6.7px 5.3px rgba(0, 0, 0, 0.008),
    0 12.5px 10px rgba(0, 0, 0, 0.01),
    0 22.3px 17.9px rgba(0, 0, 0, 0.002),
    0 41.8px 33.4px rgba(0, 0, 0, 0.006),
    0 100px 80px rgba(0, 0, 0, 0.02)
  ;
    height: relative;
    width: 40vw;
    min-height: 120px;
    min-width:300px; 
    /* margin: 100px auto; */
    background: white;
    border-radius: 5px;

  }
    /* payment success animation */

 .circle-loader {
	 margin-bottom: 3.5em;
	 border: 1px solid rgba(0, 0, 0, 0.2);
	 border-left-color: #5cb85c;
	 animation: loader-spin 1.2s infinite linear;
	 position: relative;
	 display: inline-block;
	 vertical-align: top;
	 border-radius: 50%;
	 width: 7em;
	 height: 7em;
}
 .loadcomplete {
	 -webkit-animation: none;
	 animation: none;
	 border-color: #5cb85c;
	 transition: border 500ms ease-out;
}
 /* .checkmark {
	 display: none;
} */
 .checkmark.draw:after {
	 animation-duration: 800ms;
	 animation-timing-function: ease;
	 animation-name: checkmark;
	 transform: scaleX(-1) rotate(135deg);
}
 .checkmark:after {
	 opacity: 1;
	 height: 3.5em;
	 width: 1.75em;
	 transform-origin: left top;
	 border-right: 3px solid #5cb85c;
	 border-top: 3px solid #5cb85c;
	 content: '';
	 left: 1.75em;
	 top: 3.5em;
	 position: absolute;
}
 @keyframes loader-spin {
	 0% {
		 transform: rotate(0deg);
	}
	 100% {
		 transform: rotate(360deg);
	}
}
 @keyframes checkmark {
	 0% {
		 height: 0;
		 width: 0;
		 opacity: 1;
	}
	 20% {
		 height: 0;
		 width: 1.75em;
		 opacity: 1;
	}
	 40% {
		 height: 3.5em;
		 width: 1.75em;
		 opacity: 1;
	}
	 100% {
		 height: 3.5em;
		 width: 1.75em;
		 opacity: 1;
	}
}

.loader1 {
   display:inline-block;
   font-size:0px;
   padding:0px;
}
.loader1 span {
   vertical-align:middle;
   border-radius:100%;
   
   display:inline-block;
   width:10px;
   height:10px;
   margin:3px 2px;
   -webkit-animation:loader1 0.8s linear infinite alternate;
   animation:loader1 0.8s linear infinite alternate;
}
.loader1 span:nth-child(1) {
   -webkit-animation-delay:-1s;
   animation-delay:-1s;
  background:rgba(36, 76, 168,0.6);
}
.loader1 span:nth-child(2) {
   -webkit-animation-delay:-0.8s;
   animation-delay:-0.8s;
  background:rgba(36, 76, 168,0.8);
}
.loader1 span:nth-child(3) {
   -webkit-animation-delay:-0.26666s;
   animation-delay:-0.26666s;
  background:rgba(36, 76, 168,1);
}
.loader1 span:nth-child(4) {
   -webkit-animation-delay:-0.8s;
   animation-delay:-0.8s;
  background:rgba(36, 76, 168,0.8);
  
}
.loader1 span:nth-child(5) {
   -webkit-animation-delay:-1s;
   animation-delay:-1s;
  background:rgba(36, 76, 168,0.4);
}

@keyframes loader1 {
   from {transform: scale(0, 0);}
   to {transform: scale(1, 1);}
}
@-webkit-keyframes loader1 {
   from {-webkit-transform: scale(0, 0);}
   to {-webkit-transform: scale(1, 1);}
}

 
</style>