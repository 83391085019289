<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
// import UsersReg from './components/UsersReg.vue'
// import HomePage from './components/HomePage.vue'

export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
/* @font-face {
  font-family: Adieu;
  src: url('../public/Adieu-Light.ttf') format('truetype');
} */
#app {
  font-family: Raleway, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  letter-spacing: 0.025em;
}
 .radio{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .progress-bar {
  width: 10px;
  height: 10px;
  border: 1px solid  #244ca8;
  border-radius: 1px;
  background-color:  #244ca8;
  padding-right: 0px;
}
.progress{
  background-color: white;
}
.mainbgcolor{
  /* background-color: rgba(82, 0, 115,1) */
  /* background-color:  #ff5c6b; */
  background-color:  #244ba8;


}
.f500{
  font-weight: 500;
} 
.f600{
  font-weight: 600;
}
.f700{
  font-weight: 700;
}
</style>
