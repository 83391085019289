<template>
  <div>
    <!-- <component :is="currentPage"></component>
    <button @click="previous">Prev</button>
    <button @click="next">Next</button> -->

    <!-- <h2 style="text-align: center; font-size: 28px; font-weight: bold; margin-bottom: 20px;">Jiba</h2> -->
    <!-- <div class="flex justify-center pb-10">
      <img src="../../public/jiba_kenia.png" alt="Jiba" width="100" />
    </div> -->
    <div class="mt-10 sm:mt-0">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Summary: Forming your company in the UK
            </h3>
            <p class="mt-1 pl-2 text-sm text-gray-600">
              We'll submit your company registration to the Companies House, UK.
              <br />
              Please make sure all the following information is correct before
              finishing your application.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2 pr-5 pl-5">
          <form @submit.prevent="complete" method="PUT">
            <div class="shadow overflow-hidden sm:rounded-md">
              <div class="px-4 py-5 bg-white sm:p-6">
                <div class="flex pb-5 justify-between">
                  <div
                    class="col-span-6 sm:col-span-3 items-start text-sm"
                    style="text-align: left"
                  >
                    Company registration for: <b>{{ searchComp }}</b>
                  </div>
                  <div
                    class="col-span-6 sm:col-span-3 flex gap-1 content-between items-center justify-end pb-5"
                  >
                    <h5 class="text-sm">Progress:</h5>
                    <div class="progress-bar"></div>
                    <div class="progress-bar progress orange"></div>
                    <div class="progress-bar progress orange"></div>
                    <div class="progress-bar progress orange"></div>
                    <div class="progress-bar progress orange"></div>
                  </div>
                </div>

                <input type="text" v-model="address" hidden />
                <input type="text" v-model="city" hidden />
                <input type="text" v-model="cityUK" hidden />
                <input type="text" v-model="companyName" hidden />
                <input type="text" v-model="country" hidden />
                <input type="text" v-model="countryUK" hidden />
                <input type="text" v-model="dob" hidden />
                <input type="text" v-model="email" hidden />
                <input type="text" v-model="id" hidden />
                <input type="text" v-model="industry" hidden />
                <input type="text" v-model="name" hidden />
                <input type="text" v-model="nationality" hidden />
                <input type="text" v-model="state" hidden />
                <input type="text" v-model="stateUK" hidden />
                <input type="text" v-model="street" hidden />
                <input type="text" v-model="streetUK" hidden />
                <input type="text" v-model="type" hidden />
                <input type="text" v-model="zip" hidden />
                <input type="text" v-model="zipUK" hidden />

                <div
                  class="pb-5 grid grid-cols-6 gap-6"
                  style="text-align: left"
                >
                  <div class="col-span-6 sm:col-span-6 gap-x-6">
                    <p class="pb-3 f600">Review your company information</p>
                    <div
                      class="col-span-6 sm:col-span-6"
                      style="
                        padding-left: 10px;
                        line-height: 1.7;
                        font-weight: 600;
                        color: rgba(0, 0, 0, 0.8);
                        font-size: 14px;
                      "
                    >
                      <p>Name: {{ companyName }}</p>
                      <p>Industry: {{ industry }}</p>
                      <p>
                        Type:
                        {{
                          type === "forprofit"
                            ? "Limited By Shares"
                            : "Limited By Guarantee"
                        }}
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-span-6 sm:col-span-6 pb-10 grid pb-5 justify-between"
                    style="text-align: left"
                  >
                    <p class="pb-3 f600" style="text-align: left">
                      Next Steps:
                    </p>
                    <div class="lg:flex lg:flex-row md:flex md:flex-row sm:grid sm:gap-y-6 gap-x-6">
                      <div class="basis-1/3">
                        <p class="fw500 pb-3">Finish Payment</p>
                        <p class="pl10">
                          Once you make payment, we will begin your company
                          incorporation.
                        </p>
                      </div>
                      <div class="basis-1/3">
                        <p class="fw500 pb-3">Get your company incorporated.</p>
                        <p class="pl10">
                          Once your company incorporation is ready, you can
                          access your documents in your account dashboard
                        </p>
                      </div>
                      <div class="basis-1/3">
                        <p class="fw500 pb-3">Open bank accounts and cards</p>
                        <p class="pl10">
                          We have streamlined the process for you. You can open
                          bank accounts and cards as soon as we finish your
                          company incorporation.
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- 
              <div
                class="col-span-6 sm:col-span-3 shadow"
                style="
                  border: 1px solid;
                  border-radius: 10px;
                  border-color: rgba(0, 0, 0, 0.1);
                  text-align: left;
                "
              >
                <p
                  style="
                    font-weight: bold;
                    font-size: 18px;
                    padding: 20px 0px 5px 20px;
                  "
                  for="comp-type"
                  class=""
                >
                  Model of Article of Association
                </p>
                <h3 style="width: 90%; padding: 0px 0px 20px 30px">
                  All limited companies must have articles of association. These
                  are rules for directors and shareholders about how to run the
                  company.
                </h3>
              </div>
              <br /> -->
                  <!-- <div
                class="col-span-6 sm:col-span-3 shadow"
                style="
                  border: 1px solid;
                  border-radius: 10px;
                  border-color: rgba(0, 0, 0, 0.1);
                  text-align: left;
                "
              >
                <p
                  style="
                    font-weight: bold;
                    font-size: 18px;
                    padding: 20px 0px 5px 20px;
                  "
                  for="comp-type"
                  class=""
                >
                  Memorandum of Association of Agreement
                </p>
                <h3 style="width: 90%; padding: 0px 0px 20px 30px">
                  All limited companies must have articles of association. These
                  are rules for directors and shareholders about how to run the
                  company.
                </h3>
              </div> -->
                  <button
                    class="f600 mainbgcolor col-span-6 sm:col-span-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-white"
                    @click="previous"
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    class="f600 col-span-6 sm:col-span-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-white"
                    style="background-color: rgb(36, 76, 168)"
                  >
                    Continue
                  </button>
                  <p style="color: red">{{ error }}</p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- This example requires Tailwind CSS v2.0+ -->
    <div
      v-if="show === true"
      class="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-center sm:items-center justify-center min-h-full text-center">
          <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
          <div 
            class="relative bg-white rounded-lg text-right overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full "
          >
          <!-- CLOSE MODAL  -->
            <div class="bg-white px-4">
              <div class="items-right justify-right mt-4 lg:mt-1">
                <svg @click="show = false" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 sm:mt-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>

              <div class="sm:flex sm:items-start items-center justify-center">

                <!-- SIGN UP FORM -->
                <!-- <div v-if="reg === true">
                  <form @submit.prevent="signUp" method="POST">
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3
                        class="text-lg leading-6 font-medium text-gray-900"
                        id="modal-title"
                        style="text-align: center"
                      >
                        Open an account
                      </h3>
                      <br />
                      <hr />
                      <br />

                      <div>
                        <p v-if="pswrdUnmatch === true">Please check your password is match.</p>
                        <p v-if="userExists === true">This user already exists. Please sign in.</p>
                      </div>

                      <div>
                        <label for="fullName" class="sr-only"
                          >Your Full Name</label
                        >
                        <input
                          id="fullName"
                          name="fullName"
                          type="text"
                          v-model="name"
                          autocomplete="text"
                          required
                          class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 mb-5 focus:outline-none focus:ring-orange-200 focus:border-orange-200 focus:z-10 sm:text-sm"
                          placeholder="First & Last Name"
                        />
                      </div>

                      <div>
                        <label for="email" class="sr-only">Email address</label>
                        <input
                          id="email"
                          name="email"
                          type="email"
                          v-model="email"
                          autocomplete="email"
                          required
                          class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 mb-5 focus:outline-none focus:ring-orange-200 focus:border-orange-200 focus:z-10 sm:text-sm"
                          placeholder="Email address"
                        />
                      </div>

                      <div>
                        <label for="password" class="sr-only">Password</label>
                        <input
                          id="password"
                          name="password"
                          type="password"
                          v-model="password"
                          autocomplete="current-password"
                          required
                          class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 mb-5 text-gray-900 focus:outline-none focus:ring-orange-200 focus:border-orange-200 focus:z-10 sm:text-sm"
                          placeholder="Password"
                        />
                      </div>

                      <div>
                        <label for="confirmPassword" class="sr-only"
                          >Confirm Password</label
                        >
                        <input
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          v-model="confirmPassword"
                          autocomplete="current-password"
                          required
                          class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-200 focus:border-orange-200 focus:z-10 sm:text-sm"
                          placeholder="Password"
                        />
                      </div>
                      <div>
                        <p style="font-size: 14px">
                          By continuing, you agree to Thriived Terms of service
                          and Privacy & Policy
                        </p>
                      </div>
                      <div>
                        <button
                          type="submit"
                          class="f600 col-span-3 sm:col-span-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                          style="background-color: rgba(82, 0, 115, 1)"
                        >
                          Create account
                        </button>
                      </div>
                      <div>
                        <p>Already have an account? <a href="" @click.prevent="siginHandler">Sign in</a></p>
                      </div>
                    </div>
                  </form>
                </div> -->





                <!-- REGISTRATION STATUS -->
                <!-- <div>
                  <p>{{info}}</p>
                </div> -->






                <!-- LOGIN FORM -->
                <!-- <div v-if="signin === true">
                  <p>Sign in</p>
                  <div>
                        <p v-if="noUser === true">No user with these credentials. Please sign up.</p>
                        <p v-if="userPassword === true">Incorrect details. Please check you details and try again.</p>
                        <p v-if="feedback === true">You are authenticated. Now you can proceed.</p>
                  </div>
                  <form
                    class="mt-8 space-y-6"
                    @submit.prevent="signIn"
                    method="POST"
                  >
                    <input type="hidden" name="remember" value="true" />
                    <div class="rounded-md shadow-sm -space-y-px">
                      <div>
                        <label for="email" class="sr-only">Email address</label>
                        <input
                          id="email"
                          name="email"
                          type="email"
                          v-model="email"
                          autocomplete="email"
                          required
                          class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 mb-5 focus:outline-none focus:ring-orange-200 focus:border-orange-200 focus:z-10 sm:text-sm"
                          placeholder="Email address"
                        />
                      </div>

                      <div>
                        <label for="password" class="sr-only">Password</label>
                        <input
                          id="password"
                          name="password"
                          type="password"
                          v-model="password"
                          autocomplete="current-password"
                          required
                          class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 mb-5 text-gray-900 focus:outline-none focus:ring-orange-200 focus:border-orange-200 focus:z-10 sm:text-sm"
                          placeholder="Password"
                        />
                      </div>
                    </div>

                    <div class="flex items-center justify-between">
                      <div class="flex items-center">
                        <input
                          id="rememberme"
                          name="rememberme"
                          type="checkbox"
                          v-model="rememberme"
                          class="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded"
                        />
                        <label
                          for="rememberme"
                          class="ml-2 block text-sm text-gray-900"
                        >
                          Remember me
                        </label>
                      </div>

                      <div class="text-sm">
                        <a
                          href="#"
                          class="font-medium text-indigo-600 hover:text-orange-500"
                        >
                          Forgot your password?
                        </a>
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-400 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                      >
                        <span
                          class="absolute left-0 inset-y-0 flex items-center pl-3"
                        >

                          <svg
                            class="h-5 w-5 text-white-400 group-hover:text-white-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </span>
                        Sign in
                      </button>
                       <div>
                        <p>Don't have an account? <a href="" @click.prevent="regHandler">Sign up</a></p>
                      </div>
                    </div>
                  </form>
                </div> -->


              <!-- USER REGISTRATION & LOGIN COMPONENT -->
              <div v-if="reg === true" class="mb-6">
                <users-reg @user-registered="signUp"></users-reg>
                <div>
                  <p>Already have an account? <a href="" @click.prevent="siginHandler" class="underline font-medium">Sign in</a></p>
                </div>
              </div>

              <div v-if="signin === true" class="mb-6">
                <users-auth2 @user-authenticated="signIn"></users-auth2>
                <div>
                  <p>Don't have an account? <a href="" @click.prevent="regHandler" class="underline font-medium">Sign up</a></p>
                </div>
              </div>

              </div>
            </div>


            <!-- <div
              class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
            >
              <button
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Deactivate
              </button>
              <button
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                @click="close"
              >
                Cancel
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <dash-footer></dash-footer>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import UsersApi from '../UsersApi'
import UsersAuth2 from '../components/UsersAuth2.vue'
import UsersReg from '../components/UsersReg.vue'
import DashFooter from '../components/DashFooter.vue'
// import CompAgree1 from './regComp/CompAgree1.vue'
// import CompAgree2 from './regComp/CompAgree2.vue'
export default {
  components:{
    UsersAuth2, UsersReg, DashFooter
  },
  data() {
    return {
      searchComp: localStorage.getItem("compName"),
      address: "",
      city: "",
      cityUK: "",
      companyName: "",
      country: "",
      countryUK: "",
      dob: "",
      email: "",
      id: "",
      industry: "",
      name: "",
      nationality: "",
      state: "",
      stateUK: "",
      street: "",
      streetUK: "",
      type: "",
      zip: "",
      zipUK: "",
      companyInfo: [],
      show: false,
      reg: true,
      signin: false,
      currentPage: "CompAgree1",
      password: "",
      confirmPassword: "",
      status: 'pending',
      payment: 'pending',
      pswrdUnmatch: false,
      userExists: false,
      err: '',
      error: '',
      info: '',
      noUser: false,
      userPassword: false,
      feedback: false,
      rememberme: false,
    };
  },
  mounted() {
    const data = JSON.parse(localStorage.getItem("companyInfo"));
    this.address = data["address"];
    this.city = data["city"];
    this.cityUK = data["cityUK"];
    this.companyName = data["companyName"];
    this.country = data["country"];
    this.countryUK = data["countryUK"];
    this.dob = data["dob"];
    this.email = data["email"];
    this.id = data["id"];
    this.industry = data["industry"];
    this.name = data["name"];
    this.nationality = data["nationality"];
    this.state = data["state"];
    this.stateUK = data["stateUK"];
    this.street = data["street"];
    this.streetUK = data["streetUK"];
    this.type = data["type"];
    this.zip = data["zip"];
    this.zipUK = data["zipUK"];

  },
  methods: {
    userIsAuth(){
      UsersApi.getUsers().then(res=>{
        if(res.data === 'NotAuth' ){
            return false
        }else{
            return true
        }
    })
    },
    signIn(){
      this.show = false
      // setTimeout(()=>{
      //      this.$router.push('/payment')
      //   }, 2000)


        //  this.noUser = false
        //  this.userPassword = false
        //  axios.post('http://localhost:3000/api/users/signin', { email: this.email, password: this.password, rememberme: this.rememberme }).then((result)=>{
        //    console.log(result)
        //     this.error = false
        //     this.feedback = false
        //    if(result.data === 'NoUser'){
        //       this.noUser = true
        //     }
        //    else if(result.data === 'PasswordNotOK'){
        //      this.userPassword = true
        //    } 
        //    else{
        //      this.feedback = true
        //      setTimeout(()=>{
        //       //  this.$router.push('/dashboard')
        //       this.show = false
        //      }, 2000)
        //    }
        //   })
        },
    signUp(){
      // this.feedback = true
      setTimeout(()=>{
        this.reg = false
        this.signin = true
      }
      ,2000)
      // this.show = true
      // const companyData = []
      // companyData.push(JSON.parse(localStorage.getItem("companyInfo")))
      // console.log(this.name, this.email, this.password, this.confirmPassword)
      // try{
      //   axios.post('http://localhost:3000/api/users/register', {fullName: this.name, email: this.email, password: this.password, confirmPassword: this.confirmPassword, status: this.status, payment: this.payment, companyInfo: this.companyInfo}).then((result)=>{
      //     console.log(result)
      //       this.pswrdUnmatch = false
      //       this.userExists = false
      //       this.err = ''
      //       if(result.data === 'PasswordNotMatch'){
      //         this.pswrdUnmatch = true
      //       }
      //       if(result.data === 'UserExists'){
      //         this.userExists = true
      //         // this.reg = false
      //         // this.signin = true
      //       }
      //       if(result.data === 'Registered'){
      //         this.reg = false
      //         this.info = "Successfully registered"
      //         this.loading = true
      //         setTimeout(()=>{
      //           this.show = false
      //           this.signin = true
      //           // this.$router.push('/payment')
      //           this.loading = false
      //         }, 2000)
      //       }
      //     }).catch(()=>{
      //       this.err = 'Something went wrong. Try again later.'
      //     })

      // }

      // catch(err){
      //   if(err.statusCode === 500){
      //     this.err = 'err'
      //   }
      // }
    },
    complete() {
      const data = {
        address: this.address,
        city: this.city,
        cityUK: this.cityUK,
        companyName: this.companyName,
        country: this.country,
        countryUK: this.countryUK,
        dob: this.dob,
        email: this.email,
        id: this.id,
        industry: this.industry,
        name: this.name,
        nationality: this.nationality,
        state: this.state,
        stateUK: this.stateUK,
        street: this.street,
        streetUK: this.streetUK,
        type: this.type,
        zip: this.zip,
        zipUK: this.zipUK,
      };
      // console.log(data)


      UsersApi.getUsers().then(res=>{
        if(res.data.auth != true){
          return this.show = true
        }else{
          try{
            data.userEmail = res.data.user.email
            axios.post('https://app.thriived.com/company-submission', data)
            .then(res=>{
              // console.log(res)
              if(res.data.result == 'success'){
                this.$router.push({ path: '/payment', query: { companyId: res.data.companyId, userId: res.data.userId }})
              }
            })
          }
          catch{
            this.error = 'Something went wrong, please try again later'
          }

        }
      })
      // console.log(data);
      // console.log(userIsAuth())
      // if(!this.userIsAuth()){
      //   this.show = true
      // }else{
      //   this.$router.push('/payment')
      // }
      // else{
      //   axios
      //     .put("http://localhost:3000/api/users/company-registration", data)
      //     .then((res) => {
      //       console.log(res);
      //     });
      //   setTimeout(() => {
      //     this.$router.push("/payment");
      //   }, 2000)
      // }
    },
    previous() {
      this.$router.push("/company-industry");
    },
    siginHandler(){
      this.signin = true
      this.reg = false

    },
    regHandler(){
      this.reg = true
      this.signin = false

    },
    
  },
};
</script>

<style scoped>
.orange {
  background-color: rgb(36, 76, 168)
}
.fw500 {
  font-weight: 600;
}
.pl10 {
  padding-left: 10px;
  font-size: 14px;
}
</style>
