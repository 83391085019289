<template>
  <div class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">

      <div>
        <!-- Insert Company Logo -->
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign into your account</h2>
        <br>
        <p v-if="msg">No user with that email. Try signing up.</p>
        <p v-if="loading">Submitting....</p>
        <p v-if="error">Check your details and try again</p>
        <p v-if="feedback">Authenticated! Redirecting...</p>
      </div>


      <form class="mt-8 space-y-6" @submit.prevent="postUserSignin" method="POST">
        <input type="hidden" name="remember" value="true">
        <div class="rounded-md shadow-sm -space-y-px">

          <div>
            <label for="email" class="sr-only">Email address</label>
            <input id="email" name="email" type="email" v-model="email" autocomplete="email" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 mb-5 focus:outline-none focus:ring-transparent focus:border-transparent focus:z-10 sm:text-sm" placeholder="Email address">
          </div>

          <div>
            <label for="password" class="sr-only">Password</label>
            <input id="password" name="password" type="password" v-model="password" autocomplete="current-password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 mb-5 text-gray-900  focus:outline-none focus:ring-transparent focus:border-transparent focus:z-10 sm:text-sm" placeholder="Password">
          </div>
          
        </div>

        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <input id="rememberme" name="rememberme" type="checkbox" v-model="rememberme" class="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded">
            <label for="rememberme" class="ml-2 block text-sm text-gray-900"> Remember me </label>
          </div>

          <div class="text-sm">
            <a href="#" class="font-medium" style="color: #244ba8;"> Forgot your password? </a>
          </div>
        </div>

        <div>
          <button type="submit" class="mainbgcolor group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white" style="background-color: #244ba8;">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Heroicon name: solid/lock-closed -->
              <svg class="h-5 w-5 text-white-400 group-hover:text-white-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
              </svg>
            </span>
            Sign in
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import UsersApi from '../UsersApi'
  import axios from 'axios';
  const url = 'https://app.thriived.com/'  
  // const localhost = 'http://localhost:3000/'
  
  export default {
    name: 'UsersAuth',
    
    data(){
      return{
        users: [],
        email: '',
        password: '',
        rememberme: false,
        loading: false,
        msg: false,
        error: false,
        feedback: false
      }
    },

    mounted(){
       UsersApi.getUsers().then(data=>{
         this.users = data.data
       })
    },

    methods:{
        postUserSignin(){
          // User Data Validation
         axios.post(`${url}signin`, { email: this.email, password: this.password, rememberme: this.rememberme }).then((result)=>{
            // console.log(result)
            this.msg = false
            this.error = false
            this.feedback = false
           if(result.data === 'NoUser'){
              this.msg = true
            }
           else if(result.data === 'PasswordNotOK'){
             this.error = true
           } 
           else{
            //  console.log(result)
             this.feedback = true
             this.$emit('user-authenticated')
           }
          //  else if(result.data === 'PasswordOK'){
          //    this.feedback = true
          //    setTimeout(()=>{
          //      this.$router.push('/dashboard')
          //    }, 2000)
          //  }

          }).catch(()=>{
            this.msg = "No user exist with that email address."
          })
        },
    },

    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input:focus{
 outline-color:  #ff5c6b; 
 outline-width: 0.5px;
}
input{
  outline-width: 0.5px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: orange;
}
</style>
