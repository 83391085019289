<template>
  <div>
    <!-- <h2 style="text-align: center; font-size: 28px; font-weight: bold; margin-bottom: 20px;">Jiba</h2>
     -->
     <!-- <div class="flex justify-center pb-10">
      <img src="../../public/jiba_kenia.png" alt="Jiba" width="100">
    </div> -->
    <div class="mt-10 sm:mt-0">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Company Type</h3>
            <p class="mt-1 text-sm text-gray-600 pt-2.5"> Choose a company type that's right for you,
              Will the company be 'limited by shares' or limited by guarantee'?</p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2 pr-5 pl-5">
          <form @submit.prevent="sendData">
            <div class="shadow overflow-hidden sm:rounded-md">
              <div class="px-4 py-5 bg-white sm:p-6" style="text-align: right;"> 
                 <div class="flex pb-5 justify-between">
                  <div class="col-span-6 sm:col-span-3 items-start text-sm" style="text-align:left;" >Company registration for: <b>{{ searchComp }}</b></div>
                  <div class="col-span-6 sm:col-span-3 flex gap-1 content-between items-center justify-end pb-5" style="text-align: right;">
                    <h5 class="text-sm">Progress:</h5>
                    <div class="progress-bar"></div>
                    <div class="progress-bar progress orange"></div>
                    <div class="progress-bar progress "></div>
                    <div class="progress-bar progress "></div>
                    <div class="progress-bar progress "></div>
                  </div>
                </div>

                <div class="grid grid-cols-6 gap-6 ">

                <!-- <p style="text-align:right;">Progress:</p> -->
                  <label @change="isActive2Method" :class="{ active: isActive2 }" class="col-span-6 sm:col-span-6 shadow" style="border: 1px solid; border-radius: 10px; border-color: rgba(0,0,0,0.1); text-align: left; padding:20px 20px 10px 20px;">
                    <input type="radio" v-model="type" name="comp-type" value="forprofit" id="comp-type" required class="radio" style="margin: 20px 10px 20px 20px;"/>
                    <label for="comp-type" class=""><b>Limited By Shares</b></label>
                    <br>
                    <div style="margin: 10px 0px 20px 10px; color: rgba(0,0,0,0.6)">
                      <p>This is the most common type of company. If owners ever want to take profits from the company, choose 'limited by shares'
                      </p>
                    </div>

                  </label>

                  <label @change="isActiveMethod" :class="{ active: isActive }" class="col-span-6 sm:col-span-6 shadow" style="border: 1px solid; border-radius: 10px; border-color: rgba(0,0,0,0.1); text-align: left; padding:20px 20px 10px 20px;">
                    <input type="radio" v-model="type" name="comp-type" value="nonprofit" id="comp-type2" required class="radio" style="margin: 20px 10px 20px 20px; color: red; border-color: pink;"/>
                    <label for="comp-type2" class=""><b>Limited By Guarantees</b></label>
                    <br>
                    <div style="margin: 10px 0px 20px 10px; color: rgba(0,0,0,0.6)">
                        <p>This is widely used for nonprofit bodies like charities and clubs. Profit is rarely given to the owners. It's usually kept within the company or used for other purposes.</p>
                    </div>

                  </label>

                    <button class="f600 mainbgcolor col-span-3 sm:col-span-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm  rounded-md text-white"  @click="previous">Back</button>
                    <button class="f600 mainbgcolor col-span-3 sm:col-span-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-white" style="background-color: #244ca8;" type="submit">Continue</button>

                </div>
                  
              </div>
        
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="ctn">

    </div>

  <dash-footer></dash-footer>
  </div>
</template>

<script>
import DashFooter from '../components/DashFooter.vue'

// import axios from 'axios'
export default {
  components:{
    DashFooter
  },
  data() {
    return{
      searchComp: localStorage.getItem('compName'),
      type: '',
      isActive: false,
      isActive2: false,
    }
  },

  methods:{
    sendData(){
      const data = {
        type: this.type
      }
      localStorage.setItem("companyInfo", JSON.stringify(Object.assign(JSON.parse(localStorage.getItem('companyInfo')), data)));

      console.log(localStorage.getItem('companyInfo'))
      this.$router.push("/company-address");
      // axios.post('http://localhost:3000/api/users/company-type', data)
      // .then(() => {
      //   this.$router.push("/company-type");
      // })
    },
    previous(){
        this.$router.push('/company-name')
    },
    isActiveMethod(){
      this.isActive2 = false;
      this.isActive = true
    },
    isActive2Method(){
      this.isActive2 = true;
      this.isActive = false
    }
  }
}
</script>

<style scoped>
  /* .ctn{
    margin-right: 20%;
    margin-left: 20%;
    height: 600px;
    border: 1px solid;
    border-radius: 15px;
    text-align: center;
  } */
  .active{
    border: 2px solid !important;
    border-color: rgba(36, 76, 168, 0.7) !important;
  }
  .orange{
    background-color:  #244ca8;
  }

</style>