<template>
  <div>
    <dash-header page-title="Overview"></dash-header>
    <h3>Overview</h3>
  </div>
</template>

<script>
import DashHeader from '../components/DashHeader.vue'

export default {
  components:{
    DashHeader
  }
  // data() {
  //   return {
  //     user,
  //     navigation,
  //     userNavigation,
  //   }
  // },
}
</script>