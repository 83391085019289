<template>
  <div>
    <!-- <h2 style="text-align: center; font-size: 28px; font-weight: bold; margin-bottom: 20px;">Jiba</h2> -->
    <!-- <div class="flex justify-center pb-10">
      <img src="../../public/jiba_kenia.png" alt="Jiba" width="100">
    </div> -->
    <div class="mt-10 sm:mt-0">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Company's Address</h3>
            <p class="mt-1 text-sm text-gray-600">Choose the company's registered business address. <br> You can use our FREE 
            virtual address or you can use you own address.</p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2 pr-5 pl-5">
          <form @submit.prevent="sendData">
            <div class="shadow overflow-hidden sm:rounded-md h-auto min-h-full mt-5 md:mt-0 bg-white">
              <div class="px-4 py-5 bg-white sm:p-6">
                 <div class="flex pb-5 justify-between">
                  <div class="col-span-6 sm:col-span-3 items-start text-sm" style="text-align:left;" >Company registration for: <b>{{ searchComp }}</b></div>
                  <div class="col-span-6 sm:col-span-3 flex gap-1 content-between items-center justify-end pb-5">
                    <h5 class="text-sm">Progress:</h5>
                    <div class="progress-bar"></div>
                    <div class="progress-bar progress orange"></div>
                    <div class="progress-bar progress orange"></div>
                    <div class="progress-bar progress "></div>
                    <div class="progress-bar progress "></div>
                  </div>
                </div>
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-6" style="text-align: left;">
                    <h2 style="font-size:18px; font-weight: bold;">Where is your company's registered business address?</h2>
                    <p style="font-size:12px;">This address will be used to send notifications to the company. You can use a business or home address that's in the Uk. <br> This address will be publicly available on the Companies House online register.</p>
                  </div>

                  <div @change="isActiveMethod2" :class="{ active: isActive2 }" class="col-span-6 sm:col-span-6 shadow" style="border: 2px solid; border-radius: 10px; border-color: rgba(0,0,0,0.1); text-align: left; padding:10px 20px 10px 20px;">
                    <label for="no-address" >
                      <input type="radio"  @change="isActiveMethod2" v-model="address" required name="comp-location" value="noAddress" id="no-address" class="radio" style="margin: 20px 10px 20px 20px;"/>
                      <label for="no-address" class=""><b>I don't have a registered business address in the UK.</b></label>
                    <!-- <br> -->
                      <div class="address">
                        <h3>Use our FREE virtual address and keep your address private.</h3>
                        <li>Same day mail scanning to your email</li>
                        <li>Address in London, England</li>
                        <li>Keeps your personal address private</li>
                        <li>Free for 12 months</li>

                      </div>
                    </label>
                  </div>

                  <div @change="isActiveMethod" :class="{ active: isActive }" class="col-span-6 sm:col-span-6 shadow" style="border: 2px solid; border-radius: 10px; border-color: rgba(0,0,0,0.1); text-align: left; padding:10px 20px 10px 20px;">
                    <label for="address-present" >

                      <input type="radio" @change="isActiveMethod" v-model="address" required name="comp-location" value="addressPresent" id="address-present" class="radio" style="margin: 20px 10px 20px 20px;"/>
                      <label for="address-present" class=""><b>I have a business registered address in the UK.</b></label>

                      <div class="address">
                        <h3>You can use your registered business address</h3>
                        <li>Address must be in England, UK</li>
                        <li>Companies House will use this address to send notifications to the director.</li>
                        <li>You can use a business or home address</li>
                        <li>The address provided will be publicly available on the Companies House online register</li>
                      </div>
                    </label>
                  
                    <div style="padding: 0px 0px 20px 20px;">
                      <div class="grid grid-cols-6 gap-6" v-if="show" style="padding-right:15px;">
                        <div class="col-span-6 sm:col-span-3">
                          <label for="country" class="block text-sm font-medium text-gray-700">Country</label>
                          <select id="country" name="country" v-model="countryUK" value="UK" autocomplete="country-name"  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            <option>United Kingdom</option>
                          </select>
                        </div>

                        <div class="col-span-6">
                          <label for="street-address" class="block text-sm font-medium text-gray-700">Street address</label>
                          <input type="text" v-model="streetUK" name="street-address" id="street-address" required autocomplete="street-address" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                        </div>

                        <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                          <label for="city" class="block text-sm font-medium text-gray-700">City</label>
                          <input type="text" v-model="cityUK" name="city" id="city" required autocomplete="address-level2" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                        </div>

                        <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label for="region" class="block text-sm font-medium text-gray-700">State / Province</label>
                          <input type="text" v-model="stateUK" name="region" id="region" required autocomplete="address-level1" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                        </div>

                        <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label for="postal-code" class="block text-sm font-medium text-gray-700">ZIP / Postal code</label>
                          <input type="text" v-model="zipUK" name="postal-code" id="postal-code" required autocomplete="postal-code" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                        </div>
                      </div>
                    </div>
                
                </div>

                <button class="f600 mainbgcolor col-span-3 sm:col-span-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-white" @click="previous">Back</button>
                <button class="f600 mainbgcolor col-span-3 sm:col-span-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-white" style="background-color: #244ca8" type="submit">Continue</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <dash-footer></dash-footer>
  </div>
</template>

<script>
import DashFooter from '../components/DashFooter.vue'

// import axios from 'axios'
export default {
  components:{
    DashFooter
  },
  data() {
    return{
      searchComp: localStorage.getItem('compName'),
      address: '',
      countryUK: '',
      streetUK: '',
      cityUK: '',
      stateUK: '',
      zipUK: '',
      show: false,
      isActive: false,
      isActive2: false
    }
  },

  methods:{
    sendData(){
      const data = {
        address: this.address,
        countryUK: this.countryUK,
        streetUK: this.streetUK,
        cityUK: this.cityUK,
        stateUK: this.stateUK,
        zipUK: this.zipUK,
      }
      // axios.post('http://localhost:3000/api/users/company-type', data)
      // .then(() => {
      //   this.$router.push("/company-industry");
      // })
      // console.log(data)
      localStorage.setItem("companyInfo", JSON.stringify(Object.assign(JSON.parse(localStorage.getItem('companyInfo')), data)));
      console.log(localStorage.getItem("companyInfo"));
      this.$router.push('/company-industry')
    },
    previous(){
      this.$router.push('/company-type')
    },

    isActiveMethod(){
      this.isActive2 = false;
      this.isActive = true;
      this.show = true;
    },
    isActiveMethod2(){
      this.isActive2 = true;
      this.isActive = false;
      this.show = false;

    }
  }
}
</script>

<style scoped>
.active{
  border: 2px solid !important; border-color: rgba(36, 76, 168, 0.7) !important;
}
.orange{
 background-color: #244ca8;
}
.address{
  margin: 8px 0px 20px 30px; color: rgba(0,0,0,0.8); font-weight: 500; font-size: 14px; line-height: 1.8;
}
</style>
