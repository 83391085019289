<template>
  <div class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">

      <div>
        <h2 class="mt-2 text-center text-3xl font-extrabold text-gray-900">Register an account</h2>
        <br>
        <p>{{ err }}</p>
        <p v-if="loading" class="text-sm">Submitting....</p>
        <p v-if="pswrdUnmatch" class="text-sm">Password doest not match.</p>
        <p v-if="userExists" class="text-sm text-danger">Email already exist. Try logging in</p>
        <p v-if="feedback === true" class="text-sm text-green-500">Registration successfull, redirecting to log in.</p>

      </div>


      <form class="mt-8 space-y-6" @submit.prevent="postUser" method="POST">
        <input type="hidden" name="remember" value="true">
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="fullName" class="sr-only">Your Full Name</label>
            <input id="fullName" name="fullName" type="text" v-model="fullName" autocomplete="text" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 mb-5 focus:z-10 focus:outline-none focus:ring-transparent focus:border-transparent" placeholder="First & Last Name">
          </div>

          <div>
            <label for="email" class="sr-only">Email address</label>
            <input id="email" name="email" type="email" v-model="email" autocomplete="email" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 mb-5 focus:z-10 focus:outline-none focus:ring-transparent focus:border-transparent" placeholder="Email address">
          </div>

          <div>
            <label for="password" class="sr-only">Password</label>
            <input id="password" name="password" type="password" v-model="password" autocomplete="current-password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 mb-5 text-gray-900  focus:z-10 focus:outline-none focus:ring-transparent focus:border-transparent" placeholder="Password">
          </div>
          
          <div>
            <label for="confirmPassword" class="sr-only">Confirm Password</label>
            <input id="confirmPassword" name="confirmPassword" type="password" v-model="confirmPassword" autocomplete="current-password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 focus:outline-none focus:ring-transparent focus:border-transparent" placeholder="Password">
          </div>
        </div>

        <div>
          <button type="submit" class="mainbgcolor group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white" style="background-color: #244ba8;">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Heroicon name: solid/lock-closed -->
              <svg class="h-5 w-5 text-white-400 group-hover:text-white-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
              </svg>
            </span>
            Register
          </button>
        </div>
      </form>
    </div>
    <!-- <dash-footer></dash-footer> -->
  </div>
</template>

<script>
  // import DashFooter from '../components/DashFooter.vue'
  import UsersApi from '../UsersApi'
  import axios from 'axios';
  const url = 'https://app.thriived.com/'  
  
  export default {
    name: 'UsersReg',

    components:{
      // DashFooter
    },
    
    data(){
      return{
        users: [],
        fullName: '',
        email: '',
        password: '',
        confirmPassword: '',
        status: 'pending',
        payment: 'pending',
        companyInfo: [],
        error: false,
        loading: false,
        msg: '',
        pswrdUnmatch: false,
        userExists: false,
        err: '',
        feedback: false
      }
    },

    mounted(){
       UsersApi.getUsers().then(data=>{
         this.users = data.data
       })
       const data = JSON.parse(localStorage.getItem("companyInfo"));
       this.email = data["email"];
       this.fullName = data["name"];
    },

    methods:{
        postUser(){
          // User Data Validation
          try{
            axios.post(`${url}register`, {fullName: this.fullName, email: this.email, password: this.password, confirmPassword: this.confirmPassword, status: this.status, payment: this.payment, companyInfo: this.companyInfo}).then((result)=>{
              console.log(result)
               this.pswrdUnmatch = false
               this.userExists = false
               this.err = ''
               if(result.data === 'PasswordNotMatch'){
                 this.pswrdUnmatch = true
               }
               if(result.data === 'UserExists'){
                 this.userExists = true
               }
               if(result.data === 'Registered'){
                 this.loading = true
                 this.feedback = true
                 this.$emit('user-registered')
                 this.loading = false
                //  setTimeout(()=>{
                //    this.$router.push('/signin')
                //    this.loading = false
                //  }, 2000)
               }
             }).catch(()=>{
               this.err = 'Something went wrong. Try again later.'
             })

          }

          catch(err){
            if(err.statusCode === 500){
              this.err = 'err'
            }
          }
        },
    },

    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input:focus{
 outline-color:  #ff5c6b;
 outline-width: 0.5px;
}
input{
  outline-width: 0.5px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: orange;
}
</style>
