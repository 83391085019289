<template>
  <div>
    <dash-header page-title="Dashboard" current=true></dash-header>
    <second-header></second-header>
   
    

    <div class="text-center sm:w-full">
      <div class="" style="margin: 30px 0px 0px 30px;">
        <p style="padding:10px 0px 0px 10px; text-align: left; font-weight: 600; color: black; font-size: 20px;">Mail service</p>
      </div>

      <div class="text-center sm:w-full">
        <div class="bg-white mx-10 my-10 lg:w-1/2 px-5 py-5 rounded text-left">
          <ul class="mb-5 space-y-2">
            <div class="flex flex-row">
              <div class="flex">
                <img src="../../public/10779261536572527.svg" alt="office" width="30" class="pr-5 flex-auto">
              </div>
              <div class="flex text-sm font-medium">
                <li class="flex-auto">A physical address in London, England for your business.</li>
              </div>
            </div>

             <div class="flex flex-row">
              <div class="flex">
                <img src="../../public/10779261536572527.svg" alt="office" width="30" class="pr-5 flex-auto">
              </div>
              <div class="flex text-sm font-medium">
                <li>Maintain privacy.</li>
              </div>
            </div>

             <div class="flex flex-row">
              <div class="flex">
                <img src="../../public/10779261536572527.svg" alt="office" width="30" class="pr-5 flex-auto">
              </div>
              <div class="flex text-sm font-medium">
                <li>24/7 accessibility.</li>
              </div>
            </div>

             <div class="flex flex-row">
              <div class="flex">
                <img src="../../public/10779261536572527.svg" alt="office" width="30" class="pr-5 flex-auto">
              </div>
              <div class="flex text-sm font-medium">
                <li>We scan to your email or forward to a physical address</li>
              </div>
            </div>
          </ul>
      
          <!-- <p>Once payment is complete our team will start the incorporation process.</p> -->
          <hr>
          <div class="text-center">
            <div class="px-2 py-2" v-show="subscribe">
              <input type="email" class="focus:ring-orange-500 border-gray-300 rounded" v-model="email" placeholder="Email address"/>
            </div>
            <div v-show="feedback">
              <p class="text-sm">{{ feedback.msg }}</p>
            </div>
            <button class="f600 mt-5 col-span-3 sm:col-span-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-white mainbgcolor" @click="payNow">Subscribe now</button>
          </div>
          <p class="text-xs mt-2 underline">First month is on us. $29/month after first month.</p>
        </div>
      </div>
     </div> 


  <dash-footer></dash-footer>
  </div>
</template>

<script>
import DashHeader from './DashHeader.vue'
import SecondHeader from './SecondHeader.vue'
import DashFooter from '../components/DashFooter.vue'

// import UsersApi from '../UsersApi'
import axios from 'axios';
// const url = 'api/users/'  
// const url = '/api/users/'
export default {
  components:{
    DashHeader,
    SecondHeader,
    DashFooter
  },
  data(){
    return{
      users: '',
      payment: '',
      userId: '',
      email: '',
      subscribe: false,
      feedback: {}
    }
  },

  // beforeCreate(){
  //   UsersApi.getUsers().then(res=>{
  //     if (res.data.auth != true){
  //       return this.$router.push('/signin')
  //     }else{
  //       this.userId = res.data.user.email
  //       this.users = res.data.user
  //       return
  //     }
  //   })
  // },

  methods:{
    payNow(){
      this.subscribe = true;
      if(this.email != ''){
        axios.post('https://app.thriived.com/mail-sub', { email: this.email })
        .then(res=>{
          if(res.data == 'success'){
            this.subscribe = false
            this.feedback = {code: 'green', msg: "We have recieved your subscription request for our Mail service. We will be in touch with you soon."}
          }else if(res.data == 'duplicate'){
            this.feedback = {code: 'red', msg: "Looks like we have already received your subscription request for our Mail service. If we haven't get back to you yet, we will be in touch with you soon."}

          }else{
            this.feedback = {code: 'red', msg: "Looks like something went wrong on our side. We're working on it. Please try again later."}
          }
        })
      }
      // return this.$router.push({ path: '/payment', query: { companyId: id, userId: this.userId }})
    }
  }
}
</script>

<style scoped>
.box {
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.004),
  0 6.7px 5.3px rgba(0, 0, 0, 0.008),
  0 12.5px 10px rgba(0, 0, 0, 0.01),
  0 22.3px 17.9px rgba(0, 0, 0, 0.002),
  0 41.8px 33.4px rgba(0, 0, 0, 0.006),
  0 100px 80px rgba(0, 0, 0, 0.02)
;
  height:150px;
  width: 20vw;
  min-height: 120px;
  min-width:300px; 
  /* margin: 100px auto; */
  background: white;
  border-radius: 5px;
}

</style>